import { render, staticRenderFns } from "./Wizard.vue?vue&type=template&id=ace34ac0&"
import script from "./Wizard.vue?vue&type=script&lang=js&"
export * from "./Wizard.vue?vue&type=script&lang=js&"
import style0 from "./Wizard.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VCard,VCol,VContainer,VIcon,VImg,VItem,VItemGroup,VResponsive,VRow,VTabItem,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("D:\\_Dr\\_git\\poliglotiki-front\\crm-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ace34ac0')) {
      api.createRecord('ace34ac0', component.options)
    } else {
      api.reload('ace34ac0', component.options)
    }
    module.hot.accept("./Wizard.vue?vue&type=template&id=ace34ac0&", function () {
      api.rerender('ace34ac0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/forms/Wizard.vue"
export default component.exports